import { ref, watchEffect } from 'vue';
import { projectFirestore } from '../firebase/config';
import { collection, where, orderBy, query, onSnapshot } from '@firebase/firestore';


const getCollection = (_collection, queries, orders) => {

  const documents = ref(null);
  const error = ref(null);
  const isLoading = ref(true);

  // register the firestore collection reference
  // SDK 8.0: let collectionRef = projectFirestore.collection(collection);
  const collectionRef = collection(projectFirestore, _collection);
  const queryArray = [];
  const orderArray = [];
  //Handle multiple queries
  console.log(`Queries: ${queries}`);
  if (queries) {
    // SDK 8
    // queries.forEach(query => {
    //   collectionRef = collectionRef.where(...query);
    // });
    queries.forEach(q => {
      queryArray.push(where(...q));
    });
  }
  console.log("Order: ", orders);
  if (orders) {
    // register the firestore collection reference
    // SDK 8 collectionRef = collectionRef.orderBy(...orders);
    orders.forEach(o => {
      orderArray.push(orderBy(...o));
    });

  }

  const q = query(collectionRef, ...queryArray, ...orderArray);

  // SDK 8
  // const unsub = collectionRef.onSnapshot(snap => {
  //   let results = [];
  //   snap.docs.forEach(doc => {
  //     // must wait for the server to create the timestamp & send it back
  //     doc.data().createdAt && results.push({ ...doc.data(), id: doc.id });
  //   });

  //   // update values
  //   documents.value = results;
  //   error.value = null;
  //   isLoading.value = false;
  // }, err => {
  //   console.log(err.message);
  //   documents.value = null;
  //   error.value = 'could not fetch the data';
  //   isLoading.value = false;
  // });

  const unsub = onSnapshot(q, (querySnapshot) => {
    const results = [];
    querySnapshot.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });
    // update values
    documents.value = results;
    error.value = null;
    isLoading.value = false;
  }, err => {
    console.log(err.message);
    documents.value = null;
    error.value = 'could not fetch the data';
    isLoading.value = false;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });

  return { isLoading, error, documents };
};

export default getCollection;