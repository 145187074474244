<template>
  <main>
    <h4>woob Tenant</h4>
    <div v-for="building in buildings" :key="building.name" class="row mb-3">
      <div class="col">
        <i class="bi bi-building"></i>
      </div>
      <div class="col">
        {{ building.name }}
      </div>
    </div>
  </main>
</template>

<script>
import getCollection from "@/composables/getCollection";

export default {
  name: "Home",
  setup() {
    const { error, documents: buildings } = getCollection(
      "buildings",
      [
        ["active", "==", true],
        ["order", ">", 0]
      ],
      [
        ["order", "asc"],
        ["name", "asc"]
      ]
    );

    return { error, buildings };
  }
};
</script>
